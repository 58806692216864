<ng-container *ngIf="me$ | async as me">
  <div class="flex items-center w-full user-info-header pb-12">
    <img class="avatar" [attr.src]="me.picture" />
    <div class="details ml-20 hide-when-collapsed prevent-flicker">
      <small>{{ 'common.welcomeUser' | tl8 }}</small>
      <span class="name">{{ me.given_name }} {{ me.family_name }}</span>
    </div>
    <button
      data-test-id="toggle-user-menu"
      [class.active]="(hasActiveLinks$ | async) && !showMenu"
      mat-icon-button
      (click)="toggleMenu()"
    >
      <mat-icon class="ml-auto">{{
        showMenu ? 'expand_more' : 'expand_less'
      }}</mat-icon>
    </button>
  </div>
  <div
    class="collapsable-menu"
    @expandOnEnter
    @collapseOnLeave
    *ngIf="showMenu"
  >
    <mat-nav-list>
      <a
        *ngIf="isAdminOrOwner$ | async"
        class="sidebar-link"
        data-test-id="validation-requests-menu-item"
        routerLinkActive="link-active"
        mat-list-item
        [routerLink]="'/admin/access'"
      >
        {{ 'navigation.accessManagement' | tl8 }}
      </a>
      <a
        class="sidebar-link"
        data-test-id="your-requests-menu-item"
        routerLinkActive="link-active"
        mat-list-item
        [routerLink]="'/access/your-requests'"
        *ngIf="(isAdmin$ | async) === false && (me$ | async)"
      >
        {{ 'navigation.yourRequests' | tl8 }}
      </a>
      <a
        *ngIf="false"
        class="sidebar-link"
        data-test-id="about-menu-item"
        routerLinkActive="link-active"
        mat-list-item
        [routerLink]="'/about'"
      >
        {{ 'navigation.about' | tl8 }}
      </a>
    </mat-nav-list>
  </div>
  <a [attr.href]="logoutUrl" class="sidebar-link" mat-button>
    <mat-icon>logout</mat-icon>
    <span>{{ 'buttons.logout' | tl8 }}</span>
  </a>
</ng-container>
