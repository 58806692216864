import { PrivateCommunityInformation } from '@trp/nationaldb/interfaces';
import { UnifiedCommunityInformation } from './unified-community-information';
import { RequestedUserRole, UserRole } from './user-role';

export interface CommunityAccessRequestUser {
  email: string;
  id: string;
  name: string;
}

export enum CommunityAccessRequestStatus {
  Pending = 1,
  Rejected = 2,
  Accepted = 3,
}

export const COMMUNITY_ACCESS_REQUEST_STATUSES = [
  CommunityAccessRequestStatus.Pending,
  CommunityAccessRequestStatus.Rejected,
  CommunityAccessRequestStatus.Accepted,
];

export class CommunityAccessRequest {
  id!: string;

  community!: {
    communityInformation: UnifiedCommunityInformation<PrivateCommunityInformation>;
  };

  role!: UserRole;
  description!: string;

  createdAt!: string;

  approvedAt?: string;

  deletedAt?: string;

  rejectedAt?: string;

  status!: CommunityAccessRequestStatus;

  user!: CommunityAccessRequestUser;

  currentActiveRole?: RequestedUserRole;
}

export interface AccessRequestCommunityInfo {
  id: string;
  nationalDbId: UnifiedCommunityInformation['id'];
  name: string;
  nameLong: string;
  type: UnifiedCommunityInformation['type'];
}

export interface CommunityAccessRequestFilter {
  status: CommunityAccessRequestStatus; // ('rejected' | 'pending' | 'accepted')[];
  name: string;
  communityName: string;
  roles: RequestedUserRole[];
  search: string; // Used by the general search for the old "Community Access Request" table, probably deprecated soon.
}

export interface CommunityAccessRequestSort {
  property:
    | 'userName'
    | 'communityName'
    | 'role'
    | 'roleDesc'
    | 'createdAt'
    | 'approvedDate';
  direction: 'asc' | 'desc' | '';
}
