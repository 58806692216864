import { AfterViewInit, Component, OnInit, ViewChildren } from '@angular/core';
import { NavigationEnd, Router, RouterLinkActive } from '@angular/router';
import { CollapsibleMenuService } from '@trp/community/shared/data-access-collapsible-menu';
import {
  collapseOnLeaveAnimation,
  expandOnEnterAnimation,
} from 'angular-animations';
import {
  delay,
  filter,
  map,
  startWith,
  Subject,
  Subscription,
  switchMap,
} from 'rxjs';
import { UserService } from '../services/user.service';

@Component({
  selector: 'trp-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
  animations: [expandOnEnterAnimation(), collapseOnLeaveAnimation()],
})
export class UserInfoComponent implements AfterViewInit, OnInit {
  @ViewChildren(RouterLinkActive) active!: RouterLinkActive[];
  me$ = this.userService.me$;
  loginUrl = this.userService.loginUrl;
  logoutUrl = this.userService.logoutUrl;
  isAdmin$ = this.userService.isAdmin$;
  isAdminOrOwner$ = this.userService.isAdminOrOwner$;
  private subscription = new Subscription();
  private viewInit$ = new Subject<void>();
  // There is a couple of kinks that lead to this weirdness.
  // Firstly we need to wait for afterViewInit for the query List to be populated, so we wait for this to occur before we take the observable
  // Secondly, the first NavigationEnd event is fired before the component is initialized, so we need a startWith.
  // Thirdly, the RouterLinkActives are a little bit slow to update, so we need to delay the event, hard to say what the correct hook here would be,
  // but at this point just add a delay and go on with life.
  hasActiveLinks$ = this.viewInit$.pipe(delay(400)).pipe(
    switchMap(() =>
      this.router.events.pipe(
        filter((e) => e instanceof NavigationEnd),
        map(() => [...this.active].some((a) => a.isActive)),
        startWith(() => [...this.active].some((a) => a.isActive))
      )
    )
  );

  showMenu = false;

  ngAfterViewInit() {
    this.viewInit$.next();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.collabsible.change$.subscribe(() => (this.showMenu = false))
    );
  }

  constructor(
    private userService: UserService,
    public collabsible: CollapsibleMenuService,
    private router: Router
  ) {}

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }
}
