import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  NEVER,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import {
  FeatureUserConfiguration,
  FEATURE_USER_CONFIGURATION,
} from '../feature-user-configuration';
import { Me } from '@trp/community/auth/interfaces';

export interface UserState {
  isLoading: boolean;
  loaded: boolean;
  me: Me | null;
}

export const WINDOW = new InjectionToken('WINDOW');

@Injectable({ providedIn: 'root' })
export class UserService {
  store = new ComponentStore<UserState>({
    isLoading: false,
    loaded: false,
    me: null,
  });

  me$ = this.store.select(({ me }) => me).pipe(distinctUntilChanged());
  role$ = this.me$.pipe(
    filter((me): me is Me => !!me),
    map(({ role }) => role)
  );
  isAdmin$ = this.role$.pipe(map((role) => role === 'admin'));
  isOwner$ = this.me$.pipe(
    filter((me): me is Me => !!me),
    map(({ hasOwnerRole }) => hasOwnerRole)
  );

  isAdminOrOwner$ = this.isAdmin$.pipe(
    switchMap((isAdmin) => (isAdmin ? of(true) : this.isOwner$))
  );

  isSignedIn$ = this.me$.pipe(map((me) => me !== null));
  dataLoaded$ = this.store.select(({ loaded }) => loaded);
  isVerifiedUser$ = this.store
    // %TODO% For testing purposes we count email_verified to be fully verified
    .select(({ loaded, me }) => ({ loaded, me }))
    .pipe(
      filter(({ loaded }) => loaded),
      map(
        ({ me }) =>
          me?.level === 'staff_verified' || me?.level === 'email_verified'
      ),
      distinctUntilChanged()
    );
  can(capability: 'request-access'): Observable<boolean> {
    if (capability === 'request-access') {
      return this.isVerifiedUser$;
    }
    return of(false);
  }

  constructor(
    @Inject(FEATURE_USER_CONFIGURATION)
    private configuration: FeatureUserConfiguration,
    private http: HttpClient,
    @Inject(WINDOW)
    private window: Window,
    private router: Router
  ) {}

  get loginUrl() {
    return this.getLoginUrlWithRedirectTo(window.location.href);
  }

  get logoutUrl() {
    return this.configuration.authApiUrl + '/logout';
  }

  loadUser = this.store.effect((stream$) =>
    stream$.pipe(
      tap(() => this.store.patchState({ isLoading: true, me: null })),
      switchMap(() =>
        this.http.get<Me | 'none'>('/user/me', { withCredentials: true }).pipe(
          map((me) => (me === 'none' ? null : me)),
          tap((me) => {
            if (me) {
              this.checkVerificationStatus(me);
            }
          }),
          tap((me) =>
            this.store.patchState({ isLoading: false, me, loaded: true })
          ),
          catchError((e) => {
            this.store.patchState({ isLoading: false, loaded: true });
            return NEVER;
          })
        )
      )
    )
  );

  redirectUnverifiedUser(url: string) {
    return this.isVerifiedUser$.pipe(
      map((status) => {
        return status ? true : this.router.createUrlTree([url]);
      })
    );
  }

  getLoginUrlWithRedirectTo(redirectTo: string) {
    return (
      this.configuration.authApiUrl +
      '/login?redirectTo=' +
      encodeURIComponent(redirectTo)
    );
  }

  // When the user credentials show that the email is not verified,
  // we do not know whether the user has verified their email in the meantime.
  // To check for this we perform a silent login
  private checkVerificationStatus(me: Me) {
    if (me.level === 'none') {
      // We are redirected back when the silent login concludes, in which case we do not want to do another one
      // we track this process in localStorage
      const preventSilentLogin =
        localStorage.getItem('preventSilentLogin') === 'true';
      if (preventSilentLogin) {
        localStorage.removeItem('preventSilentLogin');
        return;
      }
      localStorage.setItem('preventSilentLogin', 'true');

      // Do the silent login
      this.window.location.href =
        this.configuration.authApiUrl +
        '/login?silent=true&redirectTo=' +
        encodeURIComponent(this.window.location.href);
    }
  }
}
