import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TL8Module } from 'tl8';

@Component({
  selector: 'trp-request-status-label',
  standalone: true,
  imports: [NgIf, TL8Module, MatIconModule],
  templateUrl: './request-status-label.component.html',
  styleUrls: ['./request-status-label.component.scss'],
})
export class RequestStatusLabelComponent {
  @Input()
  status: 1 | 2 | 3 = 1;

  get statusView(): { icon: string; label: string; color: string } {
    switch (this.status) {
      case 1:
        return {
          icon: 'help',
          label: 'pending',
          color: '#ff9800',
        };
      case 2:
        return {
          icon: 'cancel',
          label: 'rejected',
          color: '#e31b0c',
        };
      case 3:
        return {
          icon: 'check_circle',
          label: 'approved',
          color: '#55be47',
        };

      default:
        return {
          icon: '',
          label: '',
          color: '',
        };
    }
  }
}
