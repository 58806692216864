export type UserAdminRole = 'user-admin';

export type UserRole = 'viewer' | 'editor' | 'owner' | UserAdminRole;

// cannot request role 'user-admin' from frontend
export type RequestedUserRole = 'viewer' | 'editor' | 'owner';

export const REQUESTED_USER_ROLES_OPTIONS: {
  value: RequestedUserRole;
  label: string;
}[] = [
  { value: 'viewer', label: 'access.role.viewer' },
  { value: 'editor', label: 'access.role.editor' },
  { value: 'owner', label: 'access.role.owner' },
];
