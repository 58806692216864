import { Injectable } from '@angular/core';
import {
  Notification,
  SimpleNotification,
  SimpleNotificationType,
} from '@trp/community/notification/interfaces';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';
import { NotificationStore } from './notification.store';

@Injectable({
  providedIn: 'root',
})
export class NotificationFacade {
  private _simpleNotification$$ =
    new BehaviorSubject<SimpleNotification | null>(null);
  private _dismissSimpleNotification$$ = new BehaviorSubject<boolean>(false);

  simpleNotification$ = combineLatest([
    this._simpleNotification$$.asObservable(),
    this._dismissSimpleNotification$$.asObservable(),
  ]).pipe(
    map(([notification, dismiss]) => {
      if (!notification) {
        return null;
      }

      return {
        ...notification,
        dismiss,
      };
    })
  );

  notifications$ = this.notificationStore.notifications$;
  hasNotifications$ = this.notifications$.pipe(
    map((notifications) => notifications.length > 0)
  );

  dismissNotifications(notificationIds: Notification['id'][]) {
    this.notificationStore.dismissNotifications(notificationIds);
  }

  dismissNotification(notificationId: Notification['id']) {
    this.dismissNotifications([notificationId]);
  }

  constructor(private notificationStore: NotificationStore) {}

  fetchNotifications() {
    this.notificationStore.triggerFetch();
  }

  getDismissableAggregate$<NotificationContent>(notificationType: string) {
    return this.getAllNotificationOfType$<NotificationContent>(
      notificationType
    ).pipe(
      map((notifications) => {
        if (notifications.length === 0) {
          return null;
        }
        return {
          count: notifications.length,
          notifications: notifications,
          dismiss: () =>
            this.dismissNotifications(notifications.map(({ id }) => id)),
        };
      })
    );
  }

  getOneNotificationOfType$<NotificationContent>(
    type: string
  ): Observable<Notification<NotificationContent>> {
    return this.getAllNotificationOfType$<NotificationContent>(type).pipe(
      map((notifications) => notifications[0])
    );
  }

  sendSimpleNotification(type: SimpleNotificationType, message: string) {
    this._simpleNotification$$.next({ type, message });
  }

  dismissSimpleNotification() {
    this._dismissSimpleNotification$$.next(true);
  }

  private getAllNotificationOfType$<NotificationContent>(
    type: string
  ): Observable<Notification<NotificationContent>[]> {
    return this.notifications$.pipe(
      map((notifications) =>
        notifications.filter(
          (notification): notification is Notification<NotificationContent> =>
            notification.type === type
        )
      )
    );
  }
}
